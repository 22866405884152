.edit-information.profile.listing{
    .breadcrumb{
        @media all and (min-width: 1200px){
            margin-bottom: 0;
        }
    }
}
.tabbing-head.listing{
    @media all and (min-width: 1440px){
        margin: 50px 0 62px;
    }
    .tabs-wrap{
        margin-top: 16px;
    }
    .tabbing-select{
        .profile-link{
            display: flex;
            align-items: center;
            border: 2px solid $white;
            color: $blue;
            padding: 6px 13px 6px 16px;
            box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
            border-radius: 2px;
            @include fontsize(16px);
            font-weight: $fw-semibold;
            background: $white;
            @media all and (min-width: 1200px){
                @include fontsize(20px);
            }
            .icon-arrow{
                background: url(../../images/icons/icon-blue-arrow.svg) no-repeat center;
                width: 9px;
                height: 14px;
                margin-right: 11px;
            }
        }
    }
    .tab-content{
        .description-wrap.listing{
            .image-block{
                @media all and (min-width: 1200px){
                    margin-bottom: 0;
                }
            }
            .seller-details{
                .details-head{
                    align-items: flex-start;
                    .main-head{
                        h2{
                            color: $gray-3;
                            margin-bottom: 5px;
                        }
                        h3{
                            font-weight: $fw-semibold;
                            @include fontsize(18px);
                            color: $gray-5;
                            @media all and (min-width: 1200px){
                                @include fontsize(20px);
                            }
                        }
                    }
                    .pricing-wrap{
                        @media all and (min-width: 1200px){
                            padding: 20px 17px;
                        }
                        .timer{
                            color: $gray-3;
                            @include fontsize(16px);
                            @media all and (min-width: 1200px){
                                @include fontsize(20px);
                            }
                        }
                    }
                }
                .details-wrap{
                    margin-bottom: 9px;
                    .detail-list{
                        @media all and (min-width: 1440px){
                            margin: 0 52px 0 0;
                        }
                        h3{
                            margin-bottom: 11px;
                        }
                        .details-values{
                            li{
                                margin-bottom: 8px;
                                .detail-text{
                                    line-height: 1.2;
                                    .day-name{
                                        min-width: 169px;
                                    }
                                    .timing{
                                        display: block;
                                        white-space: nowrap;
                                        @media all and (min-width: 1200px){
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.simple-sliders{
    margin-bottom: 36px;
    @media all and (min-width: 1200px){
        margin-bottom: 63px;
    }
    &.recommend{
        margin-bottom: 31px;
        .card-wrap{
            .service-block{
                .service-card{
                    .service-name{
                        margin-bottom: 1px;
                    }
                    h4{
                        color: $gray-2;
                        @include fontsize(16px);
                        font-weight: $fw-bold;
                        margin-bottom: 9px;
                    }
                    .seller-rating{
                        margin-bottom: 38px;
                    }
                }
            }
        }
    }
    .slider-heading{
        border-bottom: 1px solid $gray;
        padding-bottom: 11px;
        margin-bottom: 24px;
        h2{
            @include fontsize(20px);
            margin-bottom: 0;
            color: $gray-3;
            font-weight: $fw-bold;
            @media all and (min-width: 1200px){
                @include fontsize(24px);
            }
        }
    }
    .slick-slider{
        margin: 0 -13px;
        .slick-slide{
            padding: 0 13px;
        }
        .slick-list{
            overflow: hidden !important;
        }
        .slick-arrow{
            bottom: 50%;
            transform: translateY(50%);
            &.slick-prev{
                transform: translateY(50%) rotate(180deg);
                @media all and (min-width: 1440px){
                    left: -43px;
                }
            }
            &.slick-next{
                @media all and (min-width: 1440px){
                    right: -43px;
                }
            }
        }
    }
    .card-wrap{
        width: 100%;
        margin-bottom: 0;
        .service-block{
            .service-card{
                .location{
                    margin-bottom: 2px;
                }
                .seller-rating{
                    margin-bottom: 20px;
                }
            }
        }
    }
}
.add-review{
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
    @media all and (min-width: 1440px){
        margin-top: 48px;
    }
    .review-logo{
        margin-right: 7px;
        @media all and (min-width: 1200px){
            margin-right: 15px;
        }
        .text-image{
            flex-shrink: 0;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #EAF1FF;
            border-radius: 50%;
            line-height: 1.5;
            color: $blue;
            @include fontsize(14px);
            font-family: $third-fontfamily;
        }
    }
    .add-form{
        flex-grow: 1;
        form{
            .form-wrap{
                background: $white;
                box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
                border-radius: 2px;
                .textarea-wrap{
                    padding: 6px 9px 6px;
                    border-bottom: 1px solid #D6D9DE;
                    @media all and (min-width: 1200px){
                        padding: 11px 16px 11px;
                    }
                    .textarea{
                        height: 78px;
                        resize: none;
                        border: none;
                        width: 100%;
                        @include fontsize(16px);
                        &::placeholder{
                            color: $gray-2;
                        }
                        &:focus{
                            outline: none;
                        }
                    }
                }
                .rate-service{
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    @media all and (min-width: 1200px){
                        padding: 16px;
                    }
                    h4{
                        color: $gray-5;
                        font-weight: $fw-bold;
                        @include fontsize(13px);
                        margin-right: 10px;
                        @media all and (min-width: 768px){
                            @include fontsize(16px);
                            margin-right: 17px;
                        }
                    }
                    .rate-image{
                        width: 96px;
                        @media all and (min-width: 768px){
                            width: 128px;
                        }
                        img{
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }
            .btn{
                margin-top: 20px;
                padding: 7px 16px;
                @include fontsize(16px);
                text-transform: none;
                @media all and (min-width: 768px){
                    margin-top: 23px;
                }
            }
        }
    }
}