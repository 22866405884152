.seller-dialogue, .user-dialogue{
	padding: 25px 0 30px;
	@media all and (min-width: 1200px){
		padding-bottom: 62px;
	}
	.card{
		padding: 0;
	}
	.dialogue-wrap{
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		border: 0.25px solid $gray;
		@include radius(4px);	
		@media all and (min-width: 768px){
			height: 600px;
			overflow: hidden;
		}	
		@media all and (min-width: 1200px){
			height: 750px;
			overflow: hidden;
		}
	}
	.dialogue-left{
		position: relative;
		width: 100%;
		@media all and (min-width: 768px){
			width: 23%;
			overflow: hidden;
			height: 100%;
		}
		@media all and (min-width: 1200px){
			width: 15%;
		}
	}
	.users-header{
		padding: 10px 10px;
		border: 0.25px solid $gray;
		@media all and (min-width: 768px){
			border-top-left-radius: 4px;
			padding: 16px 10px;
		}
		button{
			color: $gray-5;
			background: transparent;
			outline: none;
			margin-right: 10px;
			display: inline-block;
			@media all and (min-width: 768px){
				margin-right: 0;
				display: block;
			}
			&:first-child{
				@media all and (min-width: 1200px){
					margin-bottom: 12px;
				}
			}
		}
	}
	.users-wrap{
		height: 100%;
		height: calc(100% - 72px);
		overflow: auto;
	}
	.users-list{
		@include absolute(100%, calc(100% + 20px));
		z-index: 1;
		width: 100%;
		transition: 0.3s;
		background: $white;
		@media all and (min-width: 768px){
			position: static;
			box-shadow: inset -1px 0px 0px #F0F0F0;
			padding-right: 1px;
			border-width: 0 0 0 0.25px;
			border-style: solid;
			border-color: $gray;
			border-bottom-left-radius: 4px;
		}
		&.active{
			right: 0;
		}
		li{
			@media all and (min-width: 992px){
				margin-bottom: 5px;
			}
		}
		a{
			line-height: 1.5;
			color: $gray-5;
			display: block;
			border-right: 3px solid transparent;
			padding: 8px;
			@media all and (min-width: 992px){
				padding: 9px 16px;
			}
			&.active{
				color: $blue;
				background: $blue-light;
				border-color: $blue;
				font-weight: $fw-bold;
			}
		}
	}
	.dialogue-right{
		width: 100%;
		@media all and (min-width: 768px){
			width: 77%;
			height: 100%;
		}
		@media all and (min-width: 1200px){
			width: 85%;
		}
		.user-header{
			padding: 10px 10px;
			background: $gray-5-transparent;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 0.25px solid $gray;
			border-right: 0;
			@media all and (min-width: 768px){
				padding: 16px 30px;
				border-top-right-radius: 4px;
			}
			@media all and (min-width: 1200px){
				padding: 15px 40px 20px;
			}
			.back-link{
				padding-left: 15px;
				color: $gray-2;
				position: relative;
				@media all and (min-width: 768px){
					@include fontsize(18px)
				}
				@media all and (min-width: 1200px){
					@include fontsize(20px);
					padding-left: 23px;
				}
				&:before{
					content: '';
					width: 6px;
					height: 14px;
					background: url(../../images/icons/icon-left-2.svg) no-repeat;
					@include absolute(6px, auto, auto, 0);
					@media all and (min-width: 1200px){
						width: 9px;
					}
				}
			}
			.name-wrap{
				padding: 0 10px;
				.name{
					font-weight: $fw-bold;
					display: block;
					text-align: center;
					@media all and (min-width: 768px){
						@include fontsize(18px)
					}
					@media all and (min-width: 992px){
						@include fontsize(20px)
					}
					@media all and (min-width: 1200px){
						@include fontsize(24px)
					}
				}
				.status{
					display: block;
					text-align: center;
					@include fontsize(12px);
					color: $gray-2;
					@media all and (min-width: 768px){
						@include fontsize(14px)
					}
					@media all and (min-width: 1024px){
						@include fontsize(16px)
					}
				}
			}
			.more-wrap{
				min-width: 50px;
				@media all and (min-width: 768px){
					min-width: 54px;
				}
				@media all and (min-width: 1200px){
					min-width: 66px;
				}
				button{
					display: block;
					background: transparent;
					outline: 0;
					border: 0;
					margin-left: auto;
					.icon-dots{
						display: block;
						width: 32px;
						height: 32px;
						background: url(../../images/icons/icon-dots.svg) no-repeat;
					}
				}
			}
		}
		.msg-box{
			padding: 20px 10px;
			overflow: auto;
			height: 450px;
			@media all and (min-width: 768px){
				padding: 20px 30px;
				height: calc(100% - 147px);
			}
			@media all and (min-width: 1200px){
				height: calc(100% - 171px);
				padding: 16px 28px;
			}
			@media all and (min-width: 1300px){
				height: calc(100% - 167px);
			}
			@media all and (min-width: 1400px){
				height: calc(100% - 171px);
			}
			.msg-tags{
				margin-bottom: 30px;
				@media all and (min-width: 992px){
					display: flex;
					justify-content: center;
				}
				@media all and (min-width: 1200px){
					margin-bottom: 40px;
				}
				.tag-wrap{
					text-align: center;
					@include fontsize(13px);
					color: $gray-2;
					@media all and (min-width: 992px){
						margin: 0 6px;
						position: relative;
						&:after{
							content: '';
							width: 1px;
							height: 12px;
							background: $blue;
							@include absolute(2px, -6px);
						}
						&:last-child{
							&:after{
								display: none;
							}
						}
					}
				}
			}
			.msg-wrap{
				@media all and (min-width: 768px){
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					margin-bottom: 10px;
				}
				@media all and (min-width: 1200px){
					margin-bottom: 30px;
				}
				.user-img{
					width: 72px;
					height: 72px;
					margin-bottom: 20px;
					border: 1px solid $gray-4;
					@include radius(50%);
					text-transform: capitalize;
					@include fontsize(24px);
					font-weight: $fw-bold;
					color: $blue;
					&.no-img{
						background: $gray-9;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
				.msg-details{
					@media all and (min-width: 768px){
						width: calc(100% - 87px);
						padding-top: 4px;
					}
				}
				.user-name{
					font-weight: $fw-semibold;
					margin-bottom: 10px;
					@media all and (min-width: 768px){
						@include fontsize(18px);
					}
					@media all and (min-width: 1200px){
						@include fontsize(20px);
						margin-bottom: 4px;
					}
				}
				.link-wrap{
					@media all and (min-width: 768px){
						display: inline-block;
						margin-left: 16px;
					}
					.page-link{
						color: $blue;
						border: 0.5px solid $gray;
						box-shadow: 0px 2px 4px rgba(86, 110, 146, 0.12);
						@include radius(2px);
						background: $white;
						@include fontsize(16px);
						display: inline-block;
						margin-top: 10px;
						@media all and (min-width: 768px){
							padding: 5px 18px 7px;
							margin-top: 0;
						}
						&:before{
							display: inline-block;
							content: '';
							background: url(../../images/icons/icon-blue-arrow.svg) no-repeat;
							width: 9px;
							height: 14px;
							margin-right: 10px;
						}
					}
				}
				.greet, p{
					color: $gray-5;
					line-height: 1.5;
					margin-bottom: 16px;
					letter-spacing: 0.1px;
					@media all and (min-width: 1200px){
						padding-right: 40px;
					}
				}
			}
		}
		.msg-input{
			background: $gray-11;
			padding: 10px 5px;
			border-width: 0.25px;
			border-style: solid;
			border-color: $gray;
			border-bottom-right-radius: 4px;
			border-right: 0;
			@media all and (min-width: 992px){
				padding: 10px 26px;
				border-bottom: 0;
			}
			.form-wrap{
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.clip-wrap{
				@media all and (min-width: 1200px){
					margin-left: 20px;
				}
				button{
					background: transparent;
					outline: none;
					border: 0;
					display: block;
					.icon-clip{
						background: url(../../images/icons/icon-clip.svg) no-repeat;
						background-size: cover;
						display: block;
						width: 25px;
						height: 25px;
						@media all and (min-width: 768px){
							width: 32px;
							height: 32px;
						}
					}
				}
			}
			.send-wrap{
				@media all and (min-width: 1200px){
					margin-left: 20px;
				}
				button{
					background: transparent;
					outline: none;
					border: 0;
					display: block;
					.icon-send{
						background: url(../../images/icons/icon-send.svg) no-repeat;
						background-size: cover;
						display: block;
						width: 25px;
						height: 25px;
						@media all and (min-width: 768px){
							width: 32px;
							height: 32px;
						}
					}
				}
			}
			.input-wrap{
				width: calc(100% - 80px);
				@media all and (min-width: 992px){
					width: calc(100% - 185px);
					max-width: 780px;
				}
				textarea{
					width: 100%;
					resize: none;
					outline: none;
					padding: 5px;
					border: 1px solid $gray;
					color: $gray-2;
					background: $white;
					@include radius(2px);
					@include fontsize(16px);
					letter-spacing: 0.1px;
					display: block;
					@media all and (min-width: 1200px){
						padding: 10px 14px;
						height: 65px;
					}
				}
			}
		}
	}
}