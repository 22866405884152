.footer{
	background: $gray-3;
	padding: 20px 0;
	.container{
		@media all and (min-width: 768px){
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		@media all and (min-width: 1300px){
			max-width: 1208px;
		}
		@media all and (min-width: 1300px) and (max-width: 1400px){
			max-width: 1182px;
		}
	}
	.footer-logo{
		width: 100%;
		@media all and (min-width: 992px){
			width: 25%;
		}
		.img-wrap{
			width: 152px;
			margin: 0 auto 30px;
			@media all and (min-width: 992px){
				margin: 9px 0 0;
			}
			@media all and (min-width: 1300px) and (max-width: 1400px){
				width: 146px;
			}
			@media all and (min-width: 1440px){
				margin-left: 4px;
			}
		}
	}
	.footer-menu{
		margin-bottom: 30px;
		@media all and (min-width: 768px){
			width: 30%;
			margin-bottom: 0;
		}
		@media all and (min-width: 992px){
			width: 25%;
		}
		@media all and (min-width: 1200px){
			padding-left: 3px;
			width: 20%;
		}
		li{
			margin-bottom: 4px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		a, span{
			color: $white;
			font-weight: 700;
		}
		span{
			transition: 0.3s;
			cursor: pointer;
			&:hover{
				opacity: 0.8;
			}
		}
	}
	.contact-wrap{
		margin-bottom: 30px;
		@media all and (min-width: 768px){
			width: 30%;
			margin-bottom: 0;
		}
		@media all and (min-width: 992px){
			width: 25%;
			padding-top: 5px;
		}
		address{
			@include fontsize(16px);
			color: $gray;
			line-height: 1.5;
			letter-spacing: 0.1px;
		}
	}
	.social-links{
		margin-bottom: 30px;
		@media all and (min-width: 768px){
			max-width: 30%;
			margin-bottom: 0;
		}
		@media all and (min-width: 992px){
			padding-top: 5px;
		}
		ul{
			display: flex;
		}
		li{
			& + li{
				margin-left: 8px;
			}
		}
		.icon-fb{
			display: block;
			background: url(../../images/icons/icon-fb.svg) no-repeat;
			width: 40px;
			height: 40px;
			@media all and (min-width: 1300px) and (max-width: 1400px){
				width: 36px;
				height: 36px;
				background-size: cover;
			}
		}
		.icon-twitter{
			display: block;
			background: url(../../images/icons/icon-twitter.svg) no-repeat;
			width: 40px;
			height: 40px;
			@media all and (min-width: 1300px) and (max-width: 1400px){
				width: 36px;
				height: 36px;
				background-size: cover;
			}
		}
		.icon-linkedin{
			display: block;
			background: url(../../images/icons/icon-linkedin.svg) no-repeat;
			width: 40px;
			height: 40px;
			@media all and (min-width: 1300px) and (max-width: 1400px){
				width: 36px;
				height: 36px;
				background-size: cover;
			}
		}
		.icon-insta{
			display: block;
			background: url(../../images/icons/icon-insta.svg) no-repeat;
			width: 40px;
			height: 40px;
			@media all and (min-width: 1300px) and (max-width: 1400px){
				width: 36px;
				height: 36px;
				background-size: cover;
			}
		}
	}
	.copyright-block{
		@media all and (min-width: 768px){
			padding: 30px 0 10px;
		}
		@media all and (min-width: 992px){
			padding: 38px 0 15px;
		}
		.copyright-info{
			text-align: center;
			width: 100%;
			color: $white;
		}
	}
	&.p-fix{
		padding: 14px 0 15px;
	}
}