.cancel-order{
	background: $off-white;
	width: 100%;
	max-width: 512px;
	padding: 15px;
	margin: auto;
	@media all and (min-width: 768px){
		padding: 61px 56px 81px;
	}
	h3{
		margin-bottom: 20px;
		@media all and (min-width: 768px){
			margin-bottom: 40px;
		}
	}
	.input-wrapper{
		margin-bottom: 25px;
	}
	label{
		margin-bottom: 5px;
		color: $gray-3;
		@media all and (min-width: 768px){
			margin-bottom: 12px;
		}
	}
	textarea{
		min-height: 137px;
	}
	.has-input-file {
		.img-input-wrapper {
			.img-input{
				width: 100%;
			}
		}
	}
	.wrap-btn{
		@media all and (min-width: 768px){
			margin-top: 48px;
		}
		.btn{
			width: 100%;
			@include fontsize(16px);
		}
	}
}