.sub-category-block{
	position: relative;
	padding: 30px 0 60px;
	@media all and (min-width: 1200px){
		padding: 50px 0 60px;
	}
	.container{
		max-width: 1320px;
		@media all and (min-width: 768px){
			display: flex;
			flex-wrap: wrap;
		}
		.left-block{
			@media all and (min-width: 768px){
				width: 35%;
			}
			@media all and (min-width: 1200px){
				width: 25.8%;
			}
		}
		.right-block{
			@media all and (min-width: 768px){
				width: 65%;
				padding-left: 20px;
			}
			@media all and (min-width: 1200px){
				width: 74.2%;
				padding: 0 60px 0 26px;
			}
			.head-wrap{
				margin-bottom: 20px;
				@media all and (min-width: 768px){
					display: flex;
					justify-content: space-between;
				}
				@media all and (min-width: 1200px){
					margin-bottom: 40px;
				}
				h1{
					margin-bottom: 20px;
					@media all and (min-width: 768px){
						margin-bottom: 0;
						width: 30%;
					}
				}
				.sort-select{
					@media all and (min-width: 768px){
						display: flex;
						align-items: center;
					}
					.react-select__single-value{
						font-weight: $fw-bold;
					}
					.react-select__value-container{
						padding-left: 11px;
					}
					.react-select__indicator-separator{
						display: none;
					}
					.react-select__indicator{
						background: url(../../images/icons/icon-down.svg) no-repeat center;
						margin-right: 10px;
						svg{
							display: none;
						}
					}
					label{
						margin-right: 10px;
						margin-bottom: 10px;
						@media all and (min-width: 768px){
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.filter-btn{
		button{
			background: transparent;
			padding: 3px 10px;
			border: 1px solid $gray;
			@include radius(2px);
		}
	}
	.filters-wrap{
		@include absolute(0, 100%);
		z-index: 1;
		width: 100%;
		background: $off-white;
		padding: 10px 20px;
		transition: 0.5s;
		@media all and (min-width: 768px){
			position: static;
			padding: 0;
			border-right: 1px solid $gray;
			padding-right: 15px;
		}
		@media all and (min-width: 1200px){
			padding-right: 29px;
		}
		&.active{
			right: 0;
		}
		.close-btn{
			@include absolute(10px, 14px);
			z-index: 1;
			@media all and (min-width: 768px){
				display: none;
			}
			button{
				background: transparent;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 30px;
				height: 30px;
				.icon-cross{
					background: url(../../images/icons/icon-cross-2.svg) no-repeat;
					width: 14px;
					height: 14px;
					display: block;
				}
			}
		}
		h3{
			margin-bottom: 20px;
			@media all and (min-width: 1200px){
				margin-bottom: 49px;
			}
		}
		.input-label{
			margin-bottom: 5px;
			@media all and (min-width: 768px){
				margin-bottom: 10px;
			}
		}
		.input-wrap {
			margin-bottom: 20px;
			@media all and (min-width: 768px){
				margin-bottom: 30px;
			}
			@media all and (min-width: 1200px){
				margin-bottom: 36px;
			}
			&:first-child{
				.input-label{
					@media all and (min-width: 768px){
						margin-bottom: 15px;
					}
				}
			}
			.contains-checkbox{
				display: flex;
			}
		}
		.form-check{
			margin-right: 10px;
			@media all and (min-width: 1200px){
				margin-right: 15px;
			}
			&:last-child{
				margin-right: 0;
			}
			label{
				font-weight: $fw-bold;
				color: $gray-3;
			}
		}
		.wrap-input {
			.form-control{
				background: transparent;
			}
			&.contains-btn{
				position: relative;
				.form-control{
					padding-right: 45px;
				}
				.btn-wrap{
					@include absolute(0, 0, 0);
					z-index: 1;
					button{
						background: transparent;
						display: block;
						height: 100%;
						padding: 0 7px;
						border-left: 1px solid $gray;
						.icon-search{
							background: url(../../images/icons/icon-magnifier.svg) no-repeat;
							width: 18px;
							height: 18px;
							display: block;
						}
					}
				}
			}
		}
		.multiple-inputs{
			display: flex;
			border: 1px solid $gray;
			@include radius(2px);
			.wrap-input{
				width: 50%;
				&:first-child{
					.form-control{
						background: url(../../images/icons/icon-right-3.svg) no-repeat;
						background-position: calc(100% - 10px) 12px;
						padding-right: 35px;
						border: 0;
					}
				}
				&:nth-child(2){
					.form-control{
						background: url(../../images/icons/icon-aim.svg) no-repeat;
						background-position: calc(100% - 10px) 12px;
						padding-right: 35px;
						border: 0;
					}
				}
			}
		}
		.rating-input{
			margin-bottom: 20px;
			@media all and (min-width: 768px){
				margin-bottom: 30px;
			}
			.rating-wrap{
				display: flex;
				align-items: center;
				.img-wrap{
					display: block;
					margin-right: 4px;
					img{
						display: block;
					}
				}
			}
			.text-wrap{
				@include fontsize(13px);
				color: $gray-3;
			}
		}
		.btns-wrap{
			display: flex;
			flex-wrap: wrap;
			@media all and (min-width: 768px){
				justify-content: space-between;
			}
			button{
				width: 100%;
				margin-bottom: 10px;
				padding: 6px 10px;
				@media all and (min-width: 768px){
					width: calc(50% - 7px);
				}
			}
		}
	}
	.sub-category_wrapper{
		@media all and (min-width: 768px){
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		@media all and (min-width: 992px){
			justify-content: flex-start;
			gap: 27px;
		}
		@media all and (min-width: 1366px){
			gap: 28px;
		}
		.service-block{
			background: $white;
			box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.08);
			border-radius: 4px;
			transition: all 0.5s;
			display: block;
			overflow: hidden;
			height: 100%;
			margin-bottom: 20px;
			@media all and (min-width: 768px){
				width: calc(50% - 10px);
				height: auto;
			}
			@media all and (min-width: 992px){
				width: calc(33% - 16px);
				margin-bottom: 0;
			}
			&:hover{
				opacity: 1;
				box-shadow: 2px 4px 8px rgba(89, 97, 117, 0.3);
			}
			.service-image{
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
				height: 165px;
				@media all and (min-width: 1200px){
					height: 180px;
				}
				img{
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.service-card{
				padding: 17px 16px 18px 15px;
				height: calc(100% - 165px);
				display: flex;
				flex-direction: column;
				@media all and (min-width: 1200px){
					height: calc(100% - 180px);
				}
				.service-name{
					@include fontsize(20px);
					font-weight: $fw-semibold;
					color: $gray-3;
				}
				.provider-info{
					color: $gray-5;
					@include fontsize(16px);
					font-weight: $fw-bold;
					margin-bottom: 10px;
					color: $gray-2;
				}
				.seller-rating{
					margin-bottom: 44px;
					@media all and (min-width: 1200px){
						margin-bottom: 38px;
					}
					.rating-wrap{
						display: flex;
						.rating-image{
							width: 128px;
							margin-right: 9px;
							img{
								display: block;
								width: 24px;
								height: 24px;
								max-width: none;
							}
						}
						.rating-number{
							color: $gray-2;
							@include fontsize(13px);
							margin-top: 8px;
						}
					}
				}
				.services-block{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: auto;
					.price{
						@include fontsize(20px);
						font-weight: $fw-bold;
						color: $gray-3;
					}
					.wishlist-btn{
						.icon-heart{
							background: url(../../images/icons/icon-heart-big.svg) no-repeat center;
							width: 32px;
							height: 28px;
							display: block;
						}
					}
				}
			}
		}
	}
	.pagination-wrap{
		margin-top: 7px;
	}
	&.search-section{
		.head-wrap{
			.tag-wrap{
				@media all and (min-width: 768px){
					width: 50%;
					display: flex;
					flex-wrap: wrap;
				}
				.search-tag{
					background: $gray;
					font-weight: $fw-semibold;
					padding: 6px 10px;
					display: flex;
					letter-spacing: -0.2px;
					margin: 0 5px 5px;
					@media all and (min-width: 768px){
						@include fontsize(18px);
						padding: 6px 19px;
						margin: 0 10px 0 0;
					}
					@media all and (min-width: 1200px){
						@include fontsize(20px);
					}
					.remove-btn{
						margin-right: 10px;
						button{
							background: transparent;
							display: inline-block;
							i.icon-cross{
								display: inline-block;
								background: url(../../images/icons/icon-cross-2.svg) no-repeat;
								background-size: cover;
								width: 12px;
								height: 12px;
							}
						}
					}
				}
			}
		}
	}
}